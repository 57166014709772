export const types = {
  UPDATE_BOXES: "UPDATE_BOXES",
  INVALIDATE: "INVALIDATE",
  UPDATE_X: "UPDATE_X",
  EDIT_BOXES: "EDIT_BOXES",
  POP_BOXES: "POP_BOXES",
  SELECTED_TEXT_BOX: "SELECTED_TEXT_BOX",
  RENDER_X: "RENDER_X",
  DECREMENT: "DECREMENT",
  RESET: "RESET",
}