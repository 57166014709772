import update from 'immutability-helper'

import axios from 'axios';

import { types } from "../actions/types";

const sendJsonToS3 = function() {
      var fname = localStorage.getItem('filename').split('.')[0] + '.json'
    var url = 'https://xplks8isk4.execute-api.us-west-2.amazonaws.com/default/json_to_s3?key=' + fname

    console.log('sending json to s3', url)
    axios.post(url, JSON.stringify(JSON.parse(JSON.stringify(localStorage))),{
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((res) => {
            console.log('POST JSON res', res)}).catch(err => {console.log('json post ERROR', err)})
}



const initialState = {
  // boxes2: [{ x: 0 }],
  boxes2: [
  ],
  canvasValid: false,
  selectText: 0,
  loading: false,
  hasErrors: false,
  
  // filename: '',
  xout: '',
  width: '',
  height: '',
  pc: '',
  back_bar: '', 
  front_bar: '',
  bar_height: '',
  duration: '',

  dvid: '',
  pre_sub: '',
  ti: '',

  render: '0',
  subscribe: '0',
  uid: '',
  email: '',
  pic: '',
  filename: '',

}

function boxReducer(state = initialState, action) {
  switch (action.type) {

// add a new box
    case types.UPDATE_BOXES:{
      console.log('REDUCERS', state, action.payload)
      // const updated_box = state.boxes2.push(action.payload)
      // console.log('updated_box', updated_box)
      console.log('before return in reducers', {       
        ...state,
        boxes2: [...state.boxes2, action.payload],
      })

      var x = {       
        ...state,
        boxes2: [...state.boxes2, action.payload],
      }

      // sendJsonToS3();
      return x
    }

// box[i] = box[i+1] for deleting a box
    case types.EDIT_BOXES:{

      return update(state, { 
    boxes2: { 
      [action.payload.index] : {$set: action.payload.value}
    }
  });

    }

// delete/pop last box 
    case types.POP_BOXES:{

    var x = { ...state, boxes2: [ ...state.boxes2.slice(0, action.payload.index), ...state.boxes2.slice(action.payload.index + 1) ] }
    
    // sendJsonToS3();

    return x
    }

    case types.UPDATE_X:{

      var x = update(state, { 
    boxes2: { 
      [action.payload.index] : {
        [action.payload.x]: {$set: action.payload.value}
      }
    }
  });
      console.log('update_X')
      // sendJsonToS3();

      return x
    }

    case types.RENDER_X:{

      var x = update(state, { 
    [action.payload.x]: {$set: action.payload.value}
    
  });

      console.log('before RENDER_X', action.payload.x, x )

      return x;
    }


    case types.INVALIDATE:{

    var x = { ...state, canvasValid : action.payload}

    return x
    }

    case types.SELECTED_TEXT_BOX:{
    return { ...state, selectText : action.payload}
    }

// set alpha for subtitles, so that transparent box still store alpha info

    case types.UPDATE_FILL:{
      const index = action.payload.index
      const value = action.payload.value


      console.log('in UPDATE_FILL', index)

    //   console.log('in UPDATE_FILL state',{
    //     ...state,
    //     boxes2: state.boxes2.map(
    //       (box, i) => i === index ? { ...box, fill: value } : box
    //     ) 
    // })

      return update(state, { 
    boxes2: { 
      [action.payload.index] : {
        fill: {$set: value}
      }
    }
  });

    }


    default:
      return state
  }



}

export default boxReducer;